import { getDownloadURL, ref, listAll } from "firebase/storage";
import { storage } from "../../firebase";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ImageData } from "../../types";


export const fetchImages = createAsyncThunk("images/fetchImages", async () => {
  const folderRef = ref(storage, "GalleryImages");
  const result = await listAll(folderRef);
  const imageData: ImageData[] = await Promise.all(
    result.items.map(async (item, index) => {
      const url = await getDownloadURL(item);
      return { url, alt: `Image ${index + 1}` }; // Generate Alt text dynamically
    })
  );
  return imageData;
});
