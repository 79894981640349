import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../../Hooks/useDispatch";
import { createSlug } from "../../../../Utils/createSlug";

import {
  fetchDraftBlogs,
  fetchPublishedBlogs,
  saveBlogDraft,
  updateBlogDraft,
  savePublishedBlog,
  deleteBlogDraft,
} from "../../../../Features/Blog/BlogThunk";

import { RootState } from "../../../../Features/store";
import { SeoAnalysis } from "../../../../types";

import BlogForm from "../BlogCreator/BlogComponents/BlogForm";
import BlogAnalysis from "../BlogCreator/BlogComponents/BlogAnalysis";
import { analyzeContent } from "../BlogCreator/BlogUtils/ContentAnalyzer";

import Button from "../../../../Components/UI Components/Buttons/Button/Button";

import "./BlogCreator.scss";

import { Blog } from "../../../../types";
import { serverTimestamp } from "firebase/firestore";
import LoadingPage from "../../../Loading/LoadingPage";

const BlogCreator: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const drafts = useSelector((state: RootState) => state.blogs.drafts);
  const published = useSelector((state: RootState) => state.blogs.published);
  const dispatch = useDispatch();

  const [blog, setBlog] = useState<Blog | null>(null);

  const [seoAnalysis, setSeoAnalysis] = useState<SeoAnalysis>({
    metaDescriptionLength: 0,
    keywordDensity: 0,
    keywordOccurrences: [],
    missingKeywords: [],
  });
  const [wordAndCharacterCount, setWordAndCharacterCount] = useState({
    wordCount: 0,
    characterCount: 0,
  });
  const [seoRating, setSeoRating] = useState(0);
  //const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  //const togglePreview = () => setIsPreviewVisible((prev) => !prev);

  const handleInputChange = (name: string, value: string) => {
    setBlog((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const calculateAnalysis = (content: string, seoDescription: string, metaKeywords: string) => {
    const plainText = content.replace(/<[^>]*>/g, "");
    const keywords = metaKeywords.split(",").map((k) => k.trim());
    const { seoAnalysis, wordCount, characterCount } = analyzeContent(
      plainText,
      seoDescription,
      keywords
    );

    setSeoAnalysis(seoAnalysis);
    setWordAndCharacterCount({ wordCount, characterCount });

    const rating = Math.min(
      100,
      Math.round(
        (
          (seoAnalysis.metaDescriptionLength > 120 && seoAnalysis.metaDescriptionLength <= 160
            ? 20
            : 0) +
          (seoAnalysis.keywordDensity > 1 && seoAnalysis.keywordDensity < 3 ? 20 : 0) +
          (wordCount > 1000 ? 20 : 0)
        ) / 1.6
      )
    );
    setSeoRating(rating);
  };

  useEffect(() => {
    dispatch(fetchDraftBlogs());
    dispatch(fetchPublishedBlogs());
  }, [dispatch]);

  const handlePreviewBlog = () => {
    if (blog && blog.slug) {
      const blogUrl = `/blog/${blog.slug}`;
      window.open(blogUrl, "_blank");
    } else {
      alert("Please ensure the blog has a valid slug before previewing.");
    }
  };

  useEffect(() => {
    if (id) {
      // Search for the blog in drafts and published collections
      const draft = drafts.find((draft) => draft.id === id);
      const publishedBlog = published.find((blog) => blog.id === id);

      if (draft) {
        setBlog(draft);
        calculateAnalysis(draft.content, draft.seoDescription, draft.metaKeywords);
      } else if (publishedBlog) {
        setBlog(publishedBlog);
        calculateAnalysis(publishedBlog.content, publishedBlog.seoDescription, publishedBlog.metaKeywords);
      } else {
        setBlog({
          id,
          title: "",
          content: "",
          seoDescription: "",
          metaKeywords: "",
          author: "",
          slug: "",
          isPublished: false,
        });
      }
    }
  }, [id, drafts, published]);

  const handleSeoAnalysis = () => {
    if (blog) {
      calculateAnalysis(blog.content, blog.seoDescription, blog.metaKeywords);
    }
  };

  const handleSaveDraft = () => {
    if (blog) {
      const slug = createSlug(blog.title);

      const blogData = { ...blog, slug }; // Attach the generated slug to the blog

      if (blog.id) {
        dispatch(updateBlogDraft(blogData)); // Update existing draft
      } else {
        dispatch(saveBlogDraft(blogData)); // Save new draft
      }
    }
  };

  const handlePublishBlog = () => {
    if (blog) {
      const slug = createSlug(blog.title);

      const blogData: Blog = {
        ...blog,
        slug,
        isPublished: true,
        publishedAt: serverTimestamp(),
      };

      dispatch(savePublishedBlog(blogData));

      // Optionally delete draft after publishing
      if (blog.id) {
        dispatch(deleteBlogDraft(blog.id));
      }
    }
  };

  return (
    <div className="blog-creator">
      <div className="blog-header">
        <h1>{id ? "Edit Blog" : "Create Blog"}</h1>
        <Button theme="success" onClick={handlePreviewBlog}>
          Preview Blog
        </Button>
      </div>
      <div className="blog-container">
        <div className="blog-main">
              <div className="blog-main">
                <BlogForm blog={blog ?? ""} onInputChange={handleInputChange} />
              </div>
              <div className="actions">
                <Button theme="success" onClick={handlePublishBlog}>
                  Publish Blog
                </Button>
                <Button theme="warning" onClick={handleSaveDraft}>
                  {id ? "Update Draft" : "Save Draft"}
                </Button>
                <Button theme="primary" onClick={handleSeoAnalysis}>
                  Analyze SEO
                </Button>
              </div>
        </div>
        <BlogAnalysis
          seoAnalysis={seoAnalysis}
          wordAndCharacterCount={wordAndCharacterCount}
          seoRating={seoRating}
        />
      </div>
    </div>
  );
};

export default BlogCreator;
