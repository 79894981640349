import React from 'react';
import './Spinner.scss';

interface SpinnerProps {
  theme?: "primary" | "secondary" | "error" | "success" | "warning" | "clear";
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  theme = "primary",
  className = "",
}) => {
  return (
    <div
      className={`spinner ${theme} ${className} `}
    ></div>
  );
};

export default Spinner;
