import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector} from "react-redux";
import { RootState } from "../../Features/store";
import LoadingPage from "../../Pages/Loading/LoadingPage";

interface AuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const { loading, user } = useSelector((state: RootState) => state.auth);

  if (loading) {
    return <LoadingPage/>;
  }

  return user ? <>{children}</> : <Navigate to="/login" replace />;
};

export default AuthRoute;
