import React from "react";
import Spinner from "../../Components/UI Components/Spinner/Spinner";
import "./LoadingPage.scss";

interface LoadingPageProps {
  message?: string;
  theme?: "primary" | "secondary" | "error" | "success" | "warning" | "clear";
}

const LoadingPage: React.FC<LoadingPageProps> = ({
  message = "Loading...",
  theme = "primary",
}) => {
  return (
    <div className="loading-page">
      <Spinner theme={theme} />
      <p className="loading-message">{message}</p>
    </div>
  );
};

export default LoadingPage;
