import React from 'react';
import { motion } from 'framer-motion';
import FullIconButton from '../../UI Components/Buttons/FullIconButton/fullIconBtn';
import './ProjectCard.scss';

interface ProjectCardProps {
  imageSrc: string;
  title: string;
  subtitle: string;
  description: string;
  date: string;
  link: string;
  linkLabel: string;
  linkIcon: React.ComponentType
  onClick?: () => void;
}


const ProjectCard: React.FC<ProjectCardProps> = ({
  imageSrc,
  title,
  subtitle,
  description,
  date,
  link,
  linkLabel,
  linkIcon,
  onClick
}) => {
  const IconComponent = linkIcon;
  return (
    
    <motion.div 
      className="project-card"
      initial={{ opacity: 0, y: 30 }} 
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, ease: 'easeOut' }} 
    >

      <motion.div 
        className="project-image"
        transition={{ duration: 0.3 }}
      >
        <img src={imageSrc} alt={`${title} cover`} />
      </motion.div>
      
      <motion.div 
        className="project-details"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, delay: 0.3 }}
      >
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <motion.div 
          className='description-container'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <p>{description}</p>
        </motion.div>
        <h4 className="project-date">{date}</h4>

        <motion.div>
            
          <FullIconButton
            theme="secondary" 
            className='project-link'
            icon={IconComponent}
            onClick={() => {
              if (onClick) {
                onClick();
              }
              window.open(link, '_blank');
            }}
          >{linkLabel}</FullIconButton>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectCard;
