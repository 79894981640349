import React from 'react';

import DashboardHeader from "./DashboardHeader/DashboardHeader";

import "./Dashboard.scss"
import Card from '../../Components/Cards/SimpleCard/SimpleCard';

const Dashboard: React.FC = () => {

  const utilities = [
    { title: "Manage Images", description: "Upload and manage your image gallery", path: "/image-dashboard" },
    { title: "Create Blog Post", description: "Write and publish new blog posts", path: "/blog-dashboard" },
    { title: "Add Code Project", description: "Add your coding projects", path: "/projectDashboard" },
  ];

  return (
    <>
      <DashboardHeader />
      <div className="dashboard-container">
        <div className="utility-grid">
          {utilities.map((utility, index) => (
            <Card
              key={index}
              title={utility.title}
              description={utility.description}
              className="utility-card"
              actionButtons={[
                {
                  label: `Go to ${utility.title}`,
                  onClick: () => {
                    window.location.href = utility.path;
                  },
                  theme: "secondary",
                },
              ]}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
