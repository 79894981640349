export const colorSchemes = [
    {
      name: "Sunny Day",
      primaryColor: "#FFCF91",
      secondaryColor: "#5AC8FA", 
      backgroundColor: "#DFF9FB", 
    },
    {
      name: "Mint Meadow",
      primaryColor: "#8febcb", 
      secondaryColor: "#b9ede3",
      backgroundColor: "#E8F6F3",
    },
    {
      name: "Lavender Fields",
      primaryColor: "#c499f2",
      secondaryColor: "#c7b5e8", 
      backgroundColor: "#F4ECF7",
    },
    {
      name: "Sunset",
      primaryColor: "#A875FF", 
      secondaryColor: "#c9713a", 
      backgroundColor: "#2F1B47", 
    },
    {
      name: "Midnight",
      primaryColor: "#552e85", 
      secondaryColor: "#4D77FF", 
      backgroundColor: "#0D1B2A", 
    },
    {
      name: "Forest Dusk",
      primaryColor: "#546957", 
      secondaryColor: "#5e472e", 
      backgroundColor: "#223332", 
    }
  ];
  