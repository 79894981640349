import React from 'react';
import Button from '../UI Components/Buttons/Button/Button';
import SmIconButton from '../UI Components/Buttons/SmallIconButton/SmallIconButton';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import './Pager.scss';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pager: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  className,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handleClick = (page: number) => {
    if (page !== currentPage) onPageChange(page);
  };

  const motionVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  return (
    <div className={`pagination ${className}`}>
      <div className="pagination-controls">
        <SmIconButton
          icon={FaArrowLeft}
          theme="primary"
          className="pagination-control-button"
          disabled={currentPage === 1}
          onClick={() => handleClick(currentPage - 1)}
        />
      </div>
      <div className="pagination-buttons">
        <AnimatePresence mode="wait">
          {pages.map((page) => (
            <motion.div
              key={page}
              className="pagination-button-wrapper"
              variants={motionVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <Button
                key={page}
                theme={page === currentPage ? 'secondary' : 'primary'}
                onClick={() => handleClick(page)}
                className={`pagination-button ${
                  page === currentPage ? 'active' : ''
                }`}
              >
                {page.toString()}
              </Button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <div className="pagination-controls">
        <SmIconButton
          icon={FaArrowRight}
          theme="primary"
          className="pagination-control-button"
          disabled={currentPage === totalPages}
          onClick={() => handleClick(currentPage + 1)}
        />
      </div>
    </div>
  );
};

export default Pager;
