import React, { useState, useEffect } from "react";
import "./ImageDashboard.scss";

import {
    uploadImage,
    listImages,
    deleteImage,
  } from "../../../Features/Firebase/FB-Utils";

import Button from "../../../Components/UI Components/Buttons/Button/Button";
import Pager from "../../../Components/Pager/Pager";
import FileUpload from "../../../Components/UI Components/FileUpload/FileUpload";

import { FaUpload } from "react-icons/fa";

const ImageDashboard: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Number of images to display per page

  const folderPath = "GalleryImages"; // Folder in Firebase Storage

  // Fetch images on component mount
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const urls = await listImages(folderPath);
        setImages(urls);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch images.");
      }
    };
    fetchImages();
  }, []);

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
      setError(null);
    }
  };

  // Handle image upload
  const handleUpload = async () => {
    if (!selectedFile) {
      setError("No file selected.");
      return;
    }
    setLoading(true);
    try {
      const path = `${folderPath}/${selectedFile.name}`;
      const url = await uploadImage(selectedFile, path);
      setImages((prev) => [...prev, url]);
      setSelectedFile(null);
    } catch (err) {
      console.error(err);
      setError("Failed to upload image.");
    } finally {
      setLoading(false);
    }
  };

  // Handle image deletion
  const handleDelete = async (url: string) => {
    setLoading(true);
    try {
      const path = decodeURIComponent(
        url.split(`${folderPath}%2F`)[1].split("?")[0]
      );
      await deleteImage(`${folderPath}/${path}`);
      setImages((prev) => prev.filter((img) => img !== url));
    } catch (err) {
      console.error(err);
      setError("Failed to delete image.");
    } finally {
      setLoading(false);
    }
  };

  // Get current page images
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentImages = images.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="image-manager">
      <h1>Image Manager</h1>

      <div className="upload-section">
        <FileUpload
          icon={FaUpload}
          placeholder="Add New Image"
          theme="primary"
          ariaLabel="File upload input"
          showPreview={true}
          onChange={handleFileChange}
        />
        <Button
          onClick={handleUpload}
          disabled={loading || !selectedFile}
          theme={"success"}
        >
          {loading ? "Uploading..." : "Upload"}
        </Button>
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="image-list">
        {currentImages.map((url, index) => (
          <div className="image-item" key={index}>
            <img src={url} alt={`Image ${index + 1}`} />
            <div className="image-controls">
              <Button
                onClick={() => handleDelete(url)}
                disabled={loading}
                theme={"error"}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>

      {images.length > itemsPerPage && (
        <Pager
          totalItems={images.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default ImageDashboard;